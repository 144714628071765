<div class="container-banner">
  <div nz-row>
    <div nz-col nzSpan="12">
      <div nz-row>
        <div nz-col nzSpan="24">
          <img src="assets/img/logopnldbanner.png" alt="">
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24">
          <p>Quer entender melhor como o PNLD funciona? <br />
            <a href="https://pnld-formacao.nees.ufal.br/" target="_blank">Faça um curso online <strong> PNLD Básico</strong>
              <span _ngcontent-docs-c3536830789="" nz-icon="" class="anticon anticon-export"><svg fill-rule="evenodd"
                  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em"
                  data-icon="export" aria-hidden="true">
                  <path
                    d="M880 912H144c-17.7 0-32-14.3-32-32V144c0-17.7 14.3-32 32-32h360c4.4 0 8 3.6 8 8v56c0 4.4-3.6 8-8 8H184v656h656V520c0-4.4 3.6-8 8-8h56c4.4 0 8 3.6 8 8v360c0 17.7-14.3 32-32 32zM770.87 199.13l-52.2-52.2a8.01 8.01 0 014.7-13.6l179.4-21c5.1-.6 9.5 3.7 8.9 8.9l-21 179.4c-.8 6.6-8.9 9.4-13.6 4.7l-52.4-52.4-256.2 256.2a8.03 8.03 0 01-11.3 0l-42.4-42.4a8.03 8.03 0 010-11.3l256.1-256.3z">
                  </path>
                </svg></span>
            </a>
          </p>
        </div>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div class="list-banner" nz-col nzSpan="24">
        <ul>
          <li><strong> <span nz-icon nzType="check" style="color: #2670e8 !important;"
                nzTheme="outline"></span></strong>Entenda a história do programa e a legislação envolvida</li>
          <li><strong> <span nz-icon nzType="check" style="color: #2670e8 !important;"
                nzTheme="outline"></span></strong>Prepare-se com antecedência para participar de uma avaliação no futuro
          </li>
          <li><strong> <span nz-icon nzType="check" style="color: #2670e8 !important;"
                nzTheme="outline"></span></strong>Curso ofertado no PNLD Formação, plataforma oficial de capacitação do
            programa</li>
            <li><strong> <span nz-icon nzType="check" style="color: #2670e8 !important;"
              nzTheme="outline"></span></strong>Próxima Edição do Curso disponível de 30/01/2025 a 31/03/2025.</li>
        </ul>



      </div>
    </div>
  </div>
</div>